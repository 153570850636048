.App {
  /*text-align: center;*/
  
}
html, body {
  height: 100%;
  overflow-x: hidden;
}

#root, .App, .App-header{ height: 100%; }
.login-form {
  max-width: 330px;
  padding: 1rem;
}

h1, h2, h3, legend { color: #89208f!important;}
.headScreen h1 {text-align: center;}
.btn-primary { background-color: #89208f!important; border-color: #89208f!important; }
.btn-link {color: #89208f!important; text-decoration: none!important;}
.btn-group .btn-primary {border-left: 1px solid #FFF!important;}
.btn-group .btn-primary:first-child  {border-left: none!important;}

.logo-header {max-height: 80px;}
.myloader {position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}
.card-body h2 {font-size: 1.5rem;}
.card-body svg {position: absolute; top: 50%; right: 20px; transform: translate(-50%, 0);}

#dropdown-user svg{font-size: 2rem;}

@keyframes slidein {
  from {
    left: 100%;
    display: block;
  }

  to {
    left: 0%;
  }
}
@keyframes slideout {
  from {
    left: 0%;
  }

  to {
    left: 100%;
    display: none;
  }
}
@keyframes hideout {
  from {
    display: block;
  }

  to {
    display: none;
  }
}

@keyframes animunder {
  from {
    left: 0%;
  }

  to {
    left: -20%;
  }
}
.animscreen {position: absolute; top: 0px; width: 100vw; min-height: 100vh; }
.animscreen .liste,  .animscreen .saisie{position: absolute; width: 100vw; min-height: calc(100vh - 80px); top: 0px; background-color: #FFF;}
.animscreen .saisie{min-height: 100%;}
/*.animscreen .liste.hidden,  .animscreen .saisie.hidden{left: 100%; }*/
.animscreen.hidden {display: none; }
.animscreen.hidden .liste {/*animation: 0.4s alternate slideout; animation-fill-mode: forwards;*/}
.animscreen.hidden .saisie {display: none;}
.animscreen.visible .liste {display: block; /*animation: 2.4s alternate slidein; animation-fill-mode: forwards;*/}
.animscreen.visible .liste.hidden {display: none;/*animation: 0.4s alternate animunder; animation-fill-mode: forwards;*/}
.animscreen.visible .saisie.hidden {display: none;/*animation: 0.4s alternate slideout; animation-fill-mode: forwards;*/}
.animscreen.visible .saisie.visible {display: block; /*animation: 0.4s alternate slidein; animation-fill-mode: forwards;*/}

.liste .content-screen {padding: 0; }
.headScreen {padding: 0px 15px; /*position: top; width: 100%; top: 0px; left: 0px; z-index: 1;*/ background-color: #FFF;}
.footerToolbar {position: fixed; bottom: 0px; width: 100%; z-index: 1; background-color: #f4f4f4; border-top: 1px solid #CCC;}

.liste-data .col-action {position: relative;}
.liste-data .col-action .tools-actions {position: absolute; top: 50%; width: 100%; right: 0px; transform: translate(0, -50%); text-align: right;}
.alternate-color {padding: 5px 12px;}
li.alternate-color {list-style-type: none; padding: 5px 10px; min-height: 48px;}
.alternate-color:nth-child(odd) {background-color: #f4f5f5; border-radius: 5px;}

fieldset {background-color: #FAFAFA; border: 1px solid #EEEEEE!important; border-radius: 3px; padding: 15px!important; margin-bottom: 1rem!important;}

.includeList {max-height: 80vh; overflow-y: scroll;}

.overlay-custom-confirm {z-index: 1100!important;}
.select-search-container {width: 100%!important;}
.select-search-options {padding-left: 5px!important;}
.select-search-option {height: auto!important; font-size: 0.7rem!important; padding: 5px 0!important;}